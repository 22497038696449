import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField} from '@material-ui/core';

const MessageBox = ({ id, organization, productId, productName, price, from, to, message, callBackMessageData }) => {
  const [fromValue, setFromValue] = useState(from);
  const [toValue, setToValue] = useState(to);
  const [messageValue, setMessageValue] = useState(message);

  // callback
  useEffect(() => {
    callBackMessageData(id, {
      "productId": productId,
      'productName': productName,
      'from': fromValue,
      'to': toValue,
      'message': messageValue
    });
  }, [fromValue, toValue, messageValue]);

  return (
    <>
        <h3>{id+1}: {organization} {productName}</h3>     
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField label="Van" onChange={(e) => setFromValue(e.target.value)} fullWidth value={fromValue}/>
          </Grid>                
          <Grid item xs={12} sm={6}>
            <TextField label="Aan" onChange={(e) => setToValue(e.target.value)} fullWidth value={toValue}/>
           </Grid>
           <Grid item xs={12} sm={12}>
              <TextField  label="Uw bericht" onChange={(e) => setMessageValue(e.target.value)} fullWidth value={messageValue}/>
           </Grid>
        </Grid>      
    </>
  );
};

export default MessageBox;
